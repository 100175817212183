import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {EventsActions} from "../actions";
import { Observable } from "rxjs";
import { CalendarEventModel, EventModel, EventsPagingMetaData } from "../../../models/interfaces/events-model";
import { selectCalendarEvents, selectEvents, selectEventsPagingMetaData, selectSidebarCalendarEvents } from "../selectors/user.selectors";
import { AppState } from "../reducers";

@Injectable({
    providedIn: 'root'
})
export class EventsFacade {
    readonly eventsList$: Observable<EventModel[] | null>  = this.store.select(selectEvents);

    readonly eventsPagingMetadata$: Observable<EventsPagingMetaData | null>  = this.store.select(selectEventsPagingMetaData);

    readonly sidebarCalendarEventsList$: Observable<CalendarEventModel[] | null>  = this.store.select(selectSidebarCalendarEvents);

    readonly calendarEventsList$: Observable<CalendarEventModel[] | null>  = this.store.select(selectCalendarEvents);

    constructor(
        private readonly store: Store<AppState>
    ) {
    }

    initEventsLoadingWithPaginationFromDate(dateFrom: Date): void {
        this.store.dispatch(EventsActions.initEventsLoadingWithPaginationFromDate({ dateFrom: dateFrom }));
    }

    resetEvents(): void {
        this.store.dispatch(EventsActions.resetEvents());
    }

    resetEventsPagingMetadata(): void {
        this.store.dispatch(EventsActions.resetEventsPagingMetadata());
    }

    setEvents(eventsData: EventModel[]): void {
        this.store.dispatch(EventsActions.setEvents({ eventsData: eventsData }));
    }

    loadAndAddEventsChunk(dateFrom: Date, dateTo?: Date, page?: number): void {
        this.store.dispatch(EventsActions.loadAndAddEventsDataChunk({ dateFrom: dateFrom, dateTo: dateTo, page: page }));
    }

    loadAndOverrideAllEventsData(dateFrom: Date, dateTo: Date, isPaginated: boolean): void {
        this.store.dispatch(EventsActions.loadAndOverrideAllEventsData({ dateFrom: dateFrom, dateTo: dateTo, isPaginated: isPaginated }));
    }

    loadSidebarCalendarEventsDataChunk(dateFrom: Date, dateTo: Date): void {
        this.store.dispatch(EventsActions.loadSidebarCalendarEventsDataChunk({ dateFrom: dateFrom, dateTo: dateTo }));
    }

    loadCalendarEventsDataChunk(dateFrom: Date, dateTo: Date): void {
        this.store.dispatch(EventsActions.loadCalendarEventsDataChunk({ dateFrom: dateFrom, dateTo: dateTo }));
    }
}
